import React from "react";
import { SuccessCheckIcon } from "../../assets";

interface SuccessModalProps {
  message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ message }) => {
  return (
    <div className="modal modalSuccessfull modal--open">
      <div className="modal__dialog">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header--logo">
              <SuccessCheckIcon />
            </div>
          </div>
          <div className="modal__body">
            <h4 className="promptMessage">{message}</h4>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default SuccessModal;
