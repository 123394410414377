import React, { useState, useEffect, useCallback, useRef } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useMessage } from "../../context/MessageContext";
import { getApi } from "../../apis/api";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";

interface MultiSelectChipsProps {
  dropdownData: any[]; // Data to display in the dropdown
  setDropdownData: (data: any) => void; // Function to update dropdown data
  selectedData: any[]; // The currently selected chips (items)
  setSelectedData: (data: any) => void; // Function to update selected chips
  apiUrl: string; // API endpoint for searching dropdown dataes
  limit?: number; // Items per page for pagination
  labelTitle?: string; // Optional label for the input
  mandatory?: boolean; // Whether the input is mandatory
  searchPlaceholder?: string; // Placeholder text for search input
  disable?: boolean; // Disbale the dropdown
  keyBindName: string; // Mapping data by key name
  limitSelection?: number; // Maximum number of selections allowed
  parentCustomerId?: string; // parentCustomerId for get customers of parent customer
  includeAllOption?: boolean; // include the "All" option
  disableAllOption?: boolean; // Disable the "All" option
  skipApiCallWhenNoParent?: boolean; // control API call based on parentCustomerId
  extendedUrl?: Record<string, string>; // Additional key-value pairs to append to the API URL
  extraParentId?: {
    key: string;
    id: string;
  };
  disabledAll?: boolean;
}

/**
 * MultiSelectChips Component: A reusable multi-select dropdown with chips functionality.
 *
 * @param {MultiSelectChipsProps} props - The component props.
 * @param props.dropdownData - Data to display in the dropdown list.
 * @param {Function} props.setDropdownData - Function to update dropdown data.
 * @param props.selectedData - The currently selected chips (items).
 * @param {Function} props.setSelectedData - Function to update selected chips.
 * @param {string} props.apiUrl - API endpoint for searching dropdown data.
 * @param {number} [props.limit=15] - Items per page for pagination.
 * @param {string} [props.labelTitle="Select"] - Optional label for the input.
 * @param {boolean} [props.mandatory=false] - Whether the input is mandatory.
 * @param {string} [props.searchPlaceholder="Search"] - Placeholder text for search input.
 * @param {boolean} [props.disable=false] - Disable the dropdown.
 * @param {string} props.keyBindName - Mapping data by key name.
 * @param {number} [props.limitSelection=1] - Maximum number of selections allowed.
 * @param {string} [props.parentCustomerId] - Parent customer ID for fetching customers related to a parent customer.
 * @param {boolean} [props.includeAllOption=false] - Option to include "All" option in the dropdown.
 * @param {boolean} [props.disableAllOption=false] - Disable the selection of the "All" option.
 * @param {boolean} [props.skipApiCallWhenNoParent=false] - When true, skips the API call if no parentCustomerId is provided.
 * @param {Object} [props.extendedUrl] - Key-value pairs to be appended as query parameters in the API call URL. Each key-value pair is added to the URL as a query parameter.
 * @param {string} props.extendedUrl.key - The key of the query parameter.
 * @param {string} props.extendedUrl.value - The value corresponding to the query parameter key.
 *
 *
 * @returns  MultiSelectChips Component
 */
const MultiSelectChips: React.FC<MultiSelectChipsProps> = ({
  dropdownData,
  setDropdownData,
  selectedData,
  setSelectedData,
  apiUrl,
  limit = 15,
  labelTitle = "Select",
  mandatory,
  searchPlaceholder = "Search",
  disable,
  keyBindName,
  limitSelection = Infinity,
  parentCustomerId,
  includeAllOption,
  disableAllOption,
  skipApiCallWhenNoParent,
  extendedUrl,
  extraParentId,
  disabledAll = false,
}) => {
  const { setMessage } = useMessage();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  /**
   * Toggles the dropdown open/close state.
   */
  const toggleDropdown = () => {
    if (!disable) {
      setIsOpened(!isOpened);
    }
  };

  /**
   * Handles selecting or deselecting an item from the dropdown or chips.
   */
  const handleSelectItem = (item: any) => {
    if (selectedData.find((selected) => selected.id === item.id)) {
      // Item is already selected, so remove it (deselect)
      setSelectedData(
        selectedData.filter((selected) => selected.id !== item.id)
      );
    } else {
      // Check if the selected items exceed the allowed limit
      if (selectedData.length >= limitSelection) {
        // Only allow adding more items if the limit is not reached
        return;
      }
      if (disableAllOption && item.id === "all") {
        return;
      }
      setSelectedData([...selectedData, item]);
    }
  };

  /**
   * Handles removing a selected chip (deselecting).
   */
  const handleRemoveChip = (item: any) => {
    if (!disable) {
      setSelectedData(
        selectedData.filter((selected) => selected.id !== item.id)
      );
    }
  };

  /**
   * Custom hook to handle clicks outside the dropdown, closing it.
   */
  const outsideClickRef = useOutsideClick<HTMLDivElement>(() => {
    setKeyword("");
    setIsOpened(false);
  });

  /**
   * Handles search input changes and resets pagination.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    setPage(1);
    setHasMore(true);
    if(!isOpened){
      setIsOpened(true);
    }
  }, []);

  /**
   * Converts the extendedUrl object into a query string.
   */
  const getExtendedUrlParams = () => {
    if (!extendedUrl) return "";
    return Object.entries(extendedUrl)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
  };

  /**
   * Fetches filtered data from the API based on the search keyword and other parameters.
   *
   * @param {boolean} reset - Whether to reset the current data or append new data.
   */
  const fetchFilteredData = useCallback(
    async (reset: boolean = false) => {
      // Skip API call if the `skipApiCallWhenNoParent` is true and parentCustomerId is not provided
      if (
        !apiUrl ||
        isLoading ||
        (skipApiCallWhenNoParent && !parentCustomerId)
      ) {
        return;
      }
      try {
        setIsLoading(true);
        let url = `${apiUrl}?limit=${limit}&page=${reset ? 1 : page}`;
        if (keyword) {
          url += `&keyword=${keyword}`;
        }
        if (parentCustomerId) {
          url += `&parentCustomerId=${parentCustomerId}`;
        }
        if (extraParentId?.id) {
          url += `&${extraParentId.key}=${extraParentId.id}`;
        }
        // Append the extended URL parameters if they exist
        const extendedUrlParams = getExtendedUrlParams();
        if (extendedUrlParams) {
          url += `&${extendedUrlParams}`;
        }

        const response = await getApi(url);

        if (response.success) {
          const userData: any = response.data || [];
          const mappedData = userData.map((item: any) => {
            return {
              id: item?.id,
              name: item?.[keyBindName],
              customerLevel: item?.customerLevel,
            };
          });
          // Add "All" option at the top if includeAllOption is true
          if (includeAllOption && userData?.length > 0) {
            const allOption = { id: "all", name: "all" };
            setDropdownData(
              reset
                ? [allOption, ...mappedData]
                : [...dropdownData, ...mappedData]
            );
          } else {
            if (parentCustomerId) {
              setDropdownData(mappedData);
            } else {
              setDropdownData(
                reset ? mappedData : [...dropdownData, ...mappedData]
              );
            }
          }
          setHasMore(userData.length >= limit);
        } else {
          setMessage(response.error?.message || GENERAL_ERROR_MESSAGE, "error");
        }
      } catch (error) {
        setMessage(GENERAL_ERROR_MESSAGE, "error");
      } finally {
        setIsLoading(false);
      }
    },
    [
      apiUrl,
      keyword,
      page,
      limit,
      setDropdownData,
      dropdownData,
      setMessage,
      isLoading,
      parentCustomerId,
      extraParentId?.id,
    ]
  );

  /**
   * Effect to load more data when scrolling reaches the bottom.
   */
  useEffect(() => {
    const handleScroll = () => {
      if (dropdownRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
        if (
          scrollTop + clientHeight >= scrollHeight - 10 &&
          !isLoading &&
          hasMore
        ) {
          setPage((prev) => prev + 1);
        }
      }
    };

    const currentRef = dropdownRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isOpened, isLoading, hasMore]);

  /**
   * Effect to trigger data fetching when the keyword changes, with a debounce mechanism.
   */
  useEffect(() => {
    if (keyword) {
      const debounceFetch = setTimeout(() => {
        fetchFilteredData(true);
      }, 1000);
      return () => clearTimeout(debounceFetch);
    } else {
      fetchFilteredData(true);
    }
  }, [apiUrl, keyword]);

  /**
   * Effect to fetch data when the page number changes.
   */
  useEffect(() => {
    if (page > 1) {
      fetchFilteredData();
    }
  }, [page]);

  /**
   * Fetches data when `parentCustomerId` changes.
   *
   * Skips the first render by checking `isFirstRender`, then triggers `fetchFilteredData`
   * on subsequent updates to `parentCustomerId`.
   */
  useEffect(() => {
    fetchFilteredData(true);
  }, [parentCustomerId, extraParentId?.id]);

  /**
   * Marks `isFirstRender` as false after the initial render.
   *
   * Runs only once due to the empty dependency array, ensuring that
   * `isFirstRender` is set to false after the component mounts.
   */
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return (
    <>
      <div className="form__Field">
        {labelTitle && (
          <label className="form__label form__label--medium">
            {labelTitle}{" "}
            {mandatory && <span className="mandatory-mark">*</span>}
          </label>
        )}
        <div
          className={`multiSelectChip ${isOpened && "open"} ${
            disable && "multiSelectChip__disabled"
          }`}
          ref={outsideClickRef}
          onClick={toggleDropdown}
          style={{
            height: selectedData.length > 0 ? "auto" : "44px",
          }}
        >
          {/* Selected chips display */}
          {selectedData.length > 0 && (
            <div className="chips">
              {selectedData.map((item) => (
                <div className="chip__item" key={item.id}>
                  {item.name}
                  <button
                    type="button"
                    className="chip__close"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveChip(item);
                    }}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Input field for searching */}
          <input
            type="text"
            id="search"
            name="search"
            value={keyword}
            onChange={handleSearch}
            placeholder={searchPlaceholder}
            disabled={disable}
          />

          {/* Dropdown list */}
          {isOpened && (
            <div className="dropdown" ref={dropdownRef}>
              {dropdownData.length > 0 ? (
                dropdownData.map((item) => (
                  <div
                    key={item.id}
                    className="dropdown__item"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        (item.id === "all" && disabledAll) ||
                        // Disable all other options if 'all' is selected
                        (selectedData.some(
                          (selected) => selected.id === "all"
                        ) &&
                          item.id !== "all") ||
                        // Disable 'all' option if any other option is selected
                        (selectedData.some(
                          (selected) => selected.id !== "all"
                        ) &&
                          item.id === "all")
                      )
                        return;
                      handleSelectItem(item);
                    }}
                  >
                    <input
                      //disabled={disabledAll && item.id === 'all'}
                      disabled={
                        (item.id === "all" && disabledAll) ||
                        // Disable all other options if 'all' is selected
                        (selectedData.some(
                          (selected) => selected.id === "all"
                        ) &&
                          item.id !== "all") ||
                        // Disable 'all' option if any other option is selected
                        (selectedData.some(
                          (selected) => selected.id !== "all"
                        ) &&
                          item.id === "all")
                      }
                      type="checkbox"
                      className=""
                      checked={
                        !!selectedData.find(
                          (selected) => selected.id === item.id
                        )
                      }
                      onChange={() => handleSelectItem(item)}
                    />
                    <span>{item?.name}</span>
                  </div>
                ))
              ) : (
                <div className="dropdown__noitem">No Data found.</div>
              )}
              {isLoading && <div className="loading">Loading...</div>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MultiSelectChips;
