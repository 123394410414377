import _ from "lodash";
import moment from "moment";
import {
  AdditionalChargeNames,
  ChargeLabels,
} from "../pages/customer-management/CustomerRateTabs/CustomerAdditionalCharges/CustomerAdditionalCharges.Types";
/**
 * Markup Types.
 */
export enum MarkupValueType {
  FIXED = "fixed",
  PERCENT = "percent",
}

/**
 * Enumeration of user roles in the system.
 */
export enum Roles {
  SUPER_ADMIN = "superAdmin",
  MANAGER = "manager",
  ACCOUNTING = "accounting",
  ADMIN = "admin",
  ADMINISTRATOR = "administrator",
  AGENT = "agent",
  AGENT_GROUP = "agentGroup",
  CONTRACTOR = "contractor",
  CUSTOMER = "customer",
  CUSTOMER_GROUP = "customerGroup",
  DISPATCHER = "dispatcher",
  EMPLOYEE = "employee",
  OPERATOR = "operator",
  SALES = "sales",
}

/**
 * Dropdown options for selecting roles in the system.
 */
export const RolesDropdown = [
  { label: "Super Admin", value: "superAdmin" },
  { label: "Admin", value: "admin" },
  { label: "Manager", value: "manager" },
  { label: "Employee", value: "employee" },
];

/**
 * Dropdown options for selecting user roles in the system.
 */
export const UserRolesDropdown = [
  { label: "Admin", value: "admin" },
  { label: "Manager", value: "manager" },
  { label: "Employee", value: "employee" },
];

/**
 * Determines whether a button should be disabled based on the presence of errors or unchanged values.
 *
 * @param {any} error - The error object containing any validation errors.
 * @param {any} value - The current form values.
 * @param {any} initialValues - The initial form values.
 * @returns {boolean} - Returns `true` if the button should be disabled, otherwise `false`.
 */
export const isButtonDisabled = (
  error: any,
  value: any,
  initialValues: any
) => {
  let status = false;

  if (Object.keys(error)?.length !== 0) {
    status = true;
  } else if (
    Object.keys(error)?.length === 0 &&
    _.isEqual(value, initialValues)
  ) {
    status = true;
  }
  return status;
};

/**
 * Converts an email address to a partially masked format.
 *
 * @param {string} str - The email address to be masked.
 * @returns {string} - The masked email address.
 */
export const convertEmailToStart = (str: string) => {
  let firstTwo = str.substring(0, 2);
  let domain = str.substring(str.indexOf("@"));
  const sanitizedEmail = firstTwo + "*****" + domain;
  return sanitizedEmail;
};

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} str - The string to be capitalized.
 * @returns {string | undefined} - The capitalized string, or `undefined` if the input is `undefined`.
 */
export const firstLetterCapital = (str: string): string | undefined => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

/**
 * Capitalizes the first letter of each word in a string.
 *
 * @param {string} str - The string to be formatted.
 * @returns {string} - The formatted string with each word capitalized.
 */
export const capitalizeEachWord = (str: string): string => {
  const Name = str?.split(" ");
  const capitalizedName = Name?.map((word) => {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
  const finalName = capitalizedName?.join(" ");
  return finalName;
};

/**
 * Converts a file to a Base64-encoded string.
 *
 * @param {any} file - The file to be converted.
 * @returns {Promise<string | ArrayBuffer | null>} - A promise that resolves to the Base64-encoded string or rejects with an error.
 */
export const convertBase64 = (
  file: any
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * Formats a phone number based on the country code.
 *
 * @param {string} phoneNumber - The phone number to be formatted.
 * @param {string} countryCode - The country code (e.g., "+1", "+91").
 * @returns {string} - The formatted phone number.
 */
export const formatPhoneNumber = (
  phoneNumber: string,
  countryCode: string
): string => {
  if (countryCode === "+1") {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    } else {
      return ("" + phoneNumber).replace(/\D/g, "");
    }
  } else if (countryCode === "+91") {
    return phoneNumber;
  } else {
    return phoneNumber;
  }
};

/**
 * Formats a phone number as it is being inputted based on the country calling code.
 *
 * @param {string} phoneNumber - The phone number being inputted.
 * @param {string} countryCallingCode - The country calling code (e.g., "+1").
 * @returns {string} - The formatted phone number.
 */
export const onInputPhNumFormat = (
  phoneNumber: string,
  countryCallingCode: string
): string => {
  const newphoneNumber = phoneNumber.replace(/\D/g, "");
  if (countryCallingCode === "+1") {
    const formatted =
      newphoneNumber.length === 0
        ? newphoneNumber
        : newphoneNumber.length < 4
        ? "(" + newphoneNumber
        : newphoneNumber.length < 7
        ? "(" +
          newphoneNumber.substring(0, 3) +
          ") " +
          newphoneNumber.substring(3, 6)
        : "(" +
          newphoneNumber.substring(0, 3) +
          ") " +
          newphoneNumber.substring(3, 6) +
          " - " +
          newphoneNumber.substring(6, 10);
    return formatted;
  } else {
    return newphoneNumber;
  }
};

/**
 * Formats a date string into a "MM/DD/YYYY" format.
 *
 * @param {string} dateString - The date string to be formatted.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export function formatDateToTime(isoString: string) {
  // Create a Date object from the ISO string
  const date = new Date(isoString);

  // Get hours and minutes
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Determine AM or PM suffix
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes and seconds to ensure two digits
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  // Construct the final formatted string
  return `${String(hours).padStart(
    2,
    "0"
  )}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
}

/**
 * Converts a time string from UTC to the local time zone and formats it as a string with AM/PM.
 *
 * @param {string | undefined} timeString - The time string to be converted.
 * @returns {string} - The formatted local time string, or `"--"` if the input is `undefined`.
 */
export const formatOrderTimeToLocal = (
  timeString: string | undefined
): string => {
  if (!timeString) return "--";

  // Combine the time string with today's date
  const today = new Date();
  const [time, modifier] = timeString.split(" "); // Split time and AM/PM
  let [hours, minutes, seconds] = time.split(":").map(Number);

  // Adjust hours based on AM/PM
  if (modifier === "PM" && hours < 12) hours += 12;
  if (modifier === "AM" && hours === 12) hours = 0;

  // Create a Date object using the current date and provided time in UTC
  const utcDate = new Date(
    Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      hours,
      minutes,
      seconds
    )
  );

  // Convert UTC time to local time
  const localDate = new Date(
    utcDate.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
  );

  // Return the local time as a string with AM/PM
  return localDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
};

export const findArrayElementByTitle = (array: any, title: string) => {
  var ids: any = [];
  array.forEach(function (entity: any) {
    ids.push(entity[title]);
  });
  return ids;
};
export const formatDateToInput = (dateString: string | undefined): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // This returns the date in "yyyy-MM-dd" format
};

export const formatTime = (createdAt: string) => {
  const date = new Date(createdAt);
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleTimeString("en-US", options);
};

// Helper function to convert Base64 to Blob
export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};
export const getTodayDate = (): string => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const dd = String(today.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};
export const addDaysToDate = (date: Date, days: number): string => {
  let newDate = moment(date).add(days, "d").format("YYYY-MM-DD");
  return newDate;

  //const result = new Date(date);
  // result.setDate(result.getDate() + days);
  // const yyyy = result.getFullYear();
  // const mm = String(result.getMonth() + 1).padStart(2, "0");
  // const dd = String(result.getDate()).padStart(2, "0");
  // return `${yyyy}-${mm}-${dd}`;
};
/**
 * Converts a number (1-10) to its word form.
 * @param num - The number to convert.
 * @returns The word form of the number.
 */
export const numberToWord = (num: number): string => {
  const words = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
  ];
  return words[num - 1] || num.toString();
};

// Utility function to generate label or placeholder text
export const getChargeLabels = (chargeName: string | undefined) => {
  const matchingChargeName = Object.values(AdditionalChargeNames).find(
    (name) => name === chargeName
  );

  if (matchingChargeName) {
    return ChargeLabels[matchingChargeName];
  }

  // Return default values if no match is found
  return { first: "Base Value", second: "Unit Value", third: "Unit Price" };
};

/**
 * Extracts the file name from a given URL.
 *
 * @param {string} url - The URL string.
 * @returns {string} The file name from the URL, or an empty string if the URL is empty.
 *
 * @example
 * getFileNameFromUrl("http://example.com/path/to/file.txt"); // "file.txt"
 */
export const getFileNameFromUrl = (url: string) => {
  if (!url) {
    return "";
  }
  const lastBackslashIndex = url.lastIndexOf("/");
  const substringAfterLastBackslash =
    lastBackslashIndex !== -1 ? url.substring(lastBackslashIndex + 1) : url;
  return substringAfterLastBackslash;
};

export const getFileNameFromPOD = (url: string) => {
  if (!url || typeof url !== "string") {
    return "";
  }
  console.log(`kayani here url 5555`, url);
  const lastBackslashIndex = url?.lastIndexOf("/");
  const substringAfterLastBackslash =
    lastBackslashIndex !== -1 ? url.substring(lastBackslashIndex + 1) : url;
  return (
    substringAfterLastBackslash.split("?")?.[0] || substringAfterLastBackslash
  );
};

export const getFileNameFromAWS = (url: string) => {
  if (!url) {
    return "";
  }
  return url.split("?")?.[0] || url;
};
