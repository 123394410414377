import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../redux/store";
import {
  Home,
  Login,
  ForgotPassword,
  Profile,
  ChangePassword,
  AddUser,
  UserList,
  Prior,
  AddCustomer,
  Customers,
  CustomerUsers,
  AddEditCustomerUsers,
  AgentList,
  AddEditAgentList,
  CustomerOrder,
  CustomerOrderHistory,
  CustomerOrderHistoryEdit,
  DispatchBoardList,
  AgentListTabs,
  MasterAgentList,
  MasterAgentListTabs,
  BilledOrderHistory,
  XcelaratorApi,
  ViewBilledOrderHistory,
  AddEditXcelaratorApi,
  AddEditXcelaratorApiTabs,
  AddCustomerOfCustomer,
  CustomerRate,
  EmailNotificationPermissions,
  InvoiceGroups,
  InvoiceManagement,
  AddEditInvoiceGroups,
  AddEditOrderInvoice,
  SettingsAdditionalCharges,
  AddEditSettingsAdditionalCharges,
  AddEditAgentAdditionalCharges,
  AddEditCustomerAdditionalCharges,
  AddEditCustomerInvoiceGroups,
  FinalizedOrders,
  SavedReports,
} from "../pages";
import { Loader } from "../components/loaders/loaders";
import Layout from "../components/layout/Layout";
import CreateReport from "../pages/report/CreateReport";

const AppRoutes: React.FC = () => {
  const { isAuthenticated, userRole, isUserOfOmniMove, customerLevel } =
    useSelector((state: RootState) => state.auth);

  /**
   * Common Routes
   *
   * This array contains the public routes that are accessible to all users,
   * typically for actions such as signing in or recovering a password.
   */
  const publicRoutes = [
    { path: "/users/sign_in", element: <Login /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
  ];

  /**
   * Protected Routes
   *
   * This array contains the protected routes that are accessible to auth users,
   * typically for actions such as User, Agent, Customer management.
   */
  const protectedRoutes = [
    { path: "/", element: <Home />, restricted: ["*"] },
    { path: "/user/profile", element: <Profile />, restricted: ["*"] },
    {
      path: "/email-notification-permissions",
      element: <EmailNotificationPermissions />,
      restricted: ["*"],
    },
    {
      path: "/users",
      element: <UserList />,
      restricted: ["superadmin", "admin", "customer", "manager"],
    },
    {
      path: "/users/add",
      element: <AddUser />,
      restricted: ["superadmin", "admin", "customer", "manager"],
    },
    {
      path: "/users/edit/:id",
      element: <AddUser />,
      restricted: ["superadmin", "admin", "customer", "manager"],
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
      restricted: ["*"],
    },
    // Customer Management
    {
      path: "/customers",
      element: <Customers />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager"]
        : ["admin", "manager"] ||
          (customerLevel === "second" && ["admin", "manager"]) ||
          (customerLevel === "third" && ["customer", "employee"]),
    },
    {
      path: "/customers/add",
      element: <AddCustomer />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager"]
        : ["admin", "manager"] ||
          (customerLevel === "second" && ["admin", "manager"]) ||
          (customerLevel === "third" && ["customer", "employee"]),
    },
    {
      path: "/customers/edit/:id",
      element: <AddCustomer />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager"]
        : ["admin", "manager"] ||
          (customerLevel === "second" && ["admin", "manager"]) ||
          (customerLevel === "third" && ["customer", "employee"]),
    },
    {
      path: "/customer-users",
      element: <CustomerUsers />,
      restricted: isUserOfOmniMove ? [] : ["admin", "manager"],
    },
    {
      path: "/customer-users/add",
      element: <AddEditCustomerUsers />,
      restricted: isUserOfOmniMove ? [] : ["admin", "manager"],
    },
    {
      path: "/customer-users/edit/:id",
      element: <AddEditCustomerUsers />,
      restricted: isUserOfOmniMove ? [] : ["admin", "manager"],
    },
    {
      path: "/customers/customers/:id",
      element: <AddCustomerOfCustomer />,
      restricted: isUserOfOmniMove ? [] : ["admin", "manager"],
    },
    {
      path: "/customer/rate/:id",
      element: <CustomerRate />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/customers/additional-charges/add",
      element: <AddEditCustomerAdditionalCharges />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/customers/additional-charges/edit/:id",
      element: <AddEditCustomerAdditionalCharges />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/customers/group-invoice-list/add",
      element: <AddEditCustomerInvoiceGroups />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/customers/group-invoice-list/edit/:id",
      element: <AddEditCustomerInvoiceGroups />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    // Agent Management
    {
      path: "/agent-list",
      element: <AgentList />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/agent-list/:agentId",
      element: <AgentListTabs />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/agent-list/additional-charges/add",
      element: <AddEditAgentAdditionalCharges />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/agent-list/additional-charges/edit/:id",
      element: <AddEditAgentAdditionalCharges />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/agent-list/add",
      element: <AddEditAgentList />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"] ||
          (customerLevel === "second" && []) ||
          (customerLevel === "third" && ["customer", "employee"]),
    },
    {
      path: "/agent-list/edit/:id",
      element: <AddEditAgentList />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/master-agent-list",
      element: <MasterAgentList />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"] ||
          (customerLevel === "second" && []) ||
          (customerLevel === "third" && ["customer", "employee"]),
    },
    {
      path: "/master-agent-list/:agentId",
      element: <MasterAgentListTabs />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    //order management
    {
      path: "/customer-order",
      element: <CustomerOrder />,
      restricted: isUserOfOmniMove
        ? []
        : ["admin", "customer", "manager", "employee"],
    },
    {
      path: "/customer-order-history",
      element: <CustomerOrderHistory />,
      restricted: isUserOfOmniMove
        ? ["agent"]
        : ["admin", "customer", "manager", "employee", "agent"],
    },
    {
      path: "/customer-order-history/:id",
      element: <CustomerOrderHistoryEdit />,
      restricted: isUserOfOmniMove
        ? ["agent"]
        : ["admin", "customer", "manager", "employee", "agent"],
    },
    {
      path: "/dispatch-board-list",
      element: <DispatchBoardList />,
      restricted: isUserOfOmniMove
        ? []
        : ["admin", "customer", "manager", "employee"],
    },
    {
      path: "/billed-order-history",
      element: <BilledOrderHistory />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee", "agent"]
        : [],
    },
    {
      path: "/billed-order-history/view/:billedID",
      element: <ViewBilledOrderHistory />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee", "agent"]
        : [],
    },
    // Api Management
    {
      path: "/Xcelarator-api",
      element: <XcelaratorApi />,
      restricted: ["*"],
    },
    {
      path: "/Xcelarator-api/add",
      element: <AddEditXcelaratorApi />,
      restricted: ["*"],
    },
    {
      path: "/Xcelarator-api/edit/:id",
      element: <AddEditXcelaratorApi />,
      restricted: ["*"],
    },
    {
      path: "/Xcelarator-api/agent/:id",
      element: <AddEditXcelaratorApiTabs />,
      restricted: ["*"],
    },
    // Account Management
    {
      path: "/group-invoice-list",
      element: <InvoiceGroups />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/group-invoice-list/add",
      element: <AddEditInvoiceGroups />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/group-invoice-list/edit/:id",
      element: <AddEditInvoiceGroups />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/invoice-list",
      element: <InvoiceManagement />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/invoice-list/add",
      element: <AddEditOrderInvoice />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/invoice-list/edit/:id",
      element: <AddEditOrderInvoice />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/finalized-orders",
      element: <FinalizedOrders />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    //Reports
    {
      path: "/create-report",
      element: <CreateReport />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/saved-reports",
      element: <SavedReports />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"] ||
          (customerLevel === "second" && []) ||
          (customerLevel === "third" && ["customer", "employee"]),
    },
    // Settings
    {
      path: "/settings-additional-charges",
      element: <SettingsAdditionalCharges />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/settings-additional-charges/add",
      element: <AddEditSettingsAdditionalCharges />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
    {
      path: "/settings-additional-charges/edit/:id",
      element: <AddEditSettingsAdditionalCharges />,
      restricted: isUserOfOmniMove
        ? ["superadmin", "admin", "manager", "employee"]
        : ["admin", "manager", "employee"],
    },
  ];

  /**
   * This array contains routes that are common and accessible under certain conditions,
   * such as setting a password using a token sent to the user.
   */
  const commonRoutes = [
    { path: "/user/set-password/:token", element: <Prior /> },
  ];

  /**
   * Checks if a route is accessible based on the user's role.
   * @param route - The route object that contains the following properties:
   *   - path: The path of the route (e.g., "/dashboard").
   *   - element: The JSX element to render for this route.
   *   - restricted (optional): An array of roles that are allowed to access this route.
   * @returns A boolean value indicating whether the route is accessible for the current user.
   *   - true if the route is accessible.
   *   - false if the route is not accessible.
   */
  const canAccessRoute = (route: {
    path: string;
    element: JSX.Element;
    restricted?: string[];
  }): boolean => {
    // If there are no restrictions, the route is not accessible
    if (route.restricted === undefined || route.restricted.length === 0) {
      return false;
    }
    // Handle routes that are universally accessible to all authenticated users
    if (route.restricted.includes("*")) {
      return true;
    }
    // Allow access if the user's role is in the restricted list
    return route.restricted.includes(userRole ?? "");
  };

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Public Routes */}
          {!isAuthenticated &&
            publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}

          {/* Protected Routes */}
          {isAuthenticated && (
            <Route element={<Layout />}>
              {protectedRoutes
                .filter((route) => canAccessRoute(route))
                .map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}
            </Route>
          )}

          {/* Common Routes */}
          {commonRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}

          {/* Default Redirect */}
          <Route
            path="*"
            element={
              <Navigate to={isAuthenticated ? "/" : "/users/sign_in"} replace />
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
