import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import { validateMaskedPhoneNumber } from "../../utils/validation";
import { useMessage } from "../../context/MessageContext";
import { onInputPhNumFormat } from "../../utils/utils";

import { XCrossIcon } from "../../assets";
import { Hazmat_Message } from "../../utils/message";
import { values } from "lodash";

interface HazardMaterial {
  itemName: string;
  packageType: string;
  unno: string;
  hazmatClass: string;
  properShippingName: string;
  packagingGroup: string;
  contact: string;
  phoneNumber: string;
  countryCallingCode: string;
  poison: boolean;
  isReportableQuantity: boolean;
  isMarinePollutant: boolean;
}

const mandatoryFields: (keyof HazardMaterial)[] = [
  "phoneNumber",
  "unno",
  "hazmatClass",
  "properShippingName",
  "packagingGroup",
  "contact",
];
interface AddHazmatDetailsModalType {
  setConfirmModal: (value: boolean) => void;
  handleSubmit: (data: any) => void;
  values: any;
  data: any;
  setData: (data: any) => void;
  rowData: any;
  rowIndex: number | null;
}

const EmailReportModal: React.FC<AddHazmatDetailsModalType> = (props) => {
  const { setConfirmModal, data, setData, rowData, rowIndex } = props;
  const [email, setEmail] = useState("");

  /**
   * Handles the closing of the modal.
   */
  const handleClose = () => {
    setConfirmModal(false);
  };

  function isValidEmail(email: any) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

  return (
    <div className="modal modal--hazmatDetail modal--open">
      <div className="modal__dialog">
        <div className="modal__content modal__content--lg">
          <div className="modal__header">
            <h4 className="modal__header--title">
              Enter Email Address
            </h4>
            <button
              className="btn btn__transparent btnClose"
              onClick={handleClose}
            >
              <XCrossIcon />
            </button>
          </div>
          <div className="modal__body">
            <div className="formRow">
                <div className="formCol">
                    <p className="para"><strong>Note:</strong> Enter email address to send report</p>
                </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="email" className="form__label">
                    Email Address <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className={`form__input form__input--sm`}
                    placeholder="Email Address"
                    value={email}
                    onChange={(e)=>{
                        setEmail(e.target.value);
                    }}
                  />
                  {email.length>1 && !isValidEmail(email) && <span className="form__error">
                    Please enter a valid email address.
                  </span>}
                </div>
              </div>
            </div>
          </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  onClick={()=>{props.values["notificationEmails"] = [email];
                    props.handleSubmit(props.values);
                  }}
                  disabled={email!=="" && isValidEmail(email)?false:true}
                >
                  Save
                </button>
              </div>
            </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default EmailReportModal;
