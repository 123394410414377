import React, {useMemo, useEffect, useState, useRef, KeyboardEvent } from "react";

import useOutsideClick from "../../../hooks/useOutsideClick";
import ConfirmModal from "../../modals/ConfirmModal";
import { useMessage } from "../../../context/MessageContext";
import AddHazmatDetailsModal from "../../modals/AddHazmatDetailsModal";
import { GENERAL_ERROR_MESSAGE } from "../../../utils/message";
import { deleteApi } from "../../../apis/api";
import {
  ActiveIcon,
  UnactiveIcon,
} from "../../../assets";

import {
  ArrowDownLightIcon,
  ArrowUpLightIcon,
  DeleteIcon,
  EditPenIcon,
  EditUserIcon,
} from "../../../assets";

interface ColumnChildren {
  header: string;
  key: string;
  type?: string;
}

interface Column {
  header: string;
  key: string;
  count?: boolean;
  sortable?: boolean;
  className: string;
  type?: string;
  children?: ColumnChildren[];
  mandatory?: boolean;
  editColumn?: boolean;
  dropdown?: boolean; // New property to indicate dropdown
  dropdownData?: any; // Dropdown data
  dolloar?: boolean;
  checkbox?: boolean; // Checkbox column
  subText?: string;
  isActive?: boolean;
}

interface Data {
  [key: string]: any;
}

interface ActionConfigTypes {
  apiUrl: string;
  urlID: string;
  successMessage: string;
  heading?: string;
  para?: string;
}

interface ItemDetailsTableProps {
  columns: Column[];
  data: Data[];
  setData: (data: any) => void;
  setLoading: (loading: boolean) => void;
  onDeleteConfig?: ActionConfigTypes;
  isEditable?: boolean;
  showDelete?: boolean;
  setFields?: (field: any) => void;
  getApiCall?: () => void;
  setHasChanges?: (hasChange: boolean) => void;
  deleteFromDataOnEdit?: boolean;
  editIcon?: boolean;
  handleEditModalOpen?: (row: any) => void;
  selectAllCheckboxes?: boolean;
  CenterClassColumnKey?: string[];
  isLTL?: boolean;
  checkAmountChange?: (hasChange: boolean) => void;
}

interface ConfirmTextTypes {
  heading: string;
  para: string;
}

const ItemDetailsTable: React.FC<ItemDetailsTableProps> = (props) => {
  const {
    columns,
    data,
    setData,
    setLoading,
    onDeleteConfig,
    isEditable,
    showDelete,
    setFields,
    getApiCall,
    setHasChanges,
    deleteFromDataOnEdit,
    editIcon,
    handleEditModalOpen,
    selectAllCheckboxes = false,
    CenterClassColumnKey,
    isLTL = false,
    checkAmountChange,
  } = props;
  const { setMessage } = useMessage();
  const selectRefSTackable: any = useRef();
  const selectRefPackageType: any = useRef();
  const selectRefClass: any = useRef();
  const selectRefUnit: any = useRef();
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const tableRef = useOutsideClick<HTMLDivElement>(() => setEditingRow(null));
  const [rowData, setRowData] = useState<any | null>(null);
  const [rowHandlerType, setRowHandlerType] = useState<string>("");
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<ConfirmTextTypes>({
    heading: "",
    para: "",
  });
  const [confirmSuccessText, setConfirmSuccessText] = useState<string>("");
  const [addHazmatDetailsModal, setAddHazmatDetailsModal] =
    useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<number | null>(null);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [checkFirstRender, setCheckFirstRender] = useState<boolean>(true);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (!sortConfig) return <ArrowDownLightIcon />;
    return sortConfig.key === key ? (
      sortConfig.direction === "ascending" ? (
        <ArrowUpLightIcon />
      ) : (
        <ArrowDownLightIcon />
      )
    ) : (
      <ArrowDownLightIcon />
    );
  };
  /**
   * Prevents invalid characters from being entered in numeric fields.
   *
   * @param {KeyboardEvent<HTMLInputElement>} e - The keyboard event for the input
   */
  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement>,
    inputType: string = "text"
  ) => {
    if (
      inputType === "number" &&
      (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+")
    ) {
      e.preventDefault();
    }
  };

  /**
   * Handles row click to enable editing mode if the table is editable.
   *
   * @param rowIndex - Index of the clicked row.
   */
  const handleRowClick = (e: any, rowIndex: number) => {
    if (!isEditable) {
      return;
    }
    if (
      e.target === selectRefSTackable.current ||
      e.target === selectRefPackageType.current ||
      e.target === selectRefUnit.current ||
      e.target === selectRefClass.current
    ) {
      return;
    }
    setEditingRow(rowIndex === editingRow ? null : rowIndex);
  };

  /**
   * Shows the confirmation modal with action details.
   *
   * @param row - Data of the row to perform the action on.
   * @param actionType - Type of the action (e.g., Delete).
   * @param confirmMsgheading - Heading for the confirmation modal.
   * @param confirmMsgheadPara - Paragraph text for the confirmation modal.
   * @param successMsg - Success message to display after the action.
   */
  const handleAction = (
    row: any,
    actionType: string,
    confirmMsgheading: string,
    confirmMsgheadPara: string,
    successMsg: string
  ) => {
    setRowData(row);
    setRowHandlerType(actionType);
    setConfirmText({
      heading: confirmMsgheading,
      para: confirmMsgheadPara,
    });
    setConfirmSuccessText(successMsg);
    setConfirmModal(true);
  };

  /**
   * Handles changes in input fields for table rows.
   *
   * @param e - The input change event.
   * @param rowIndex - Index of the row being edited.
   * @param key - Key of the column being edited.
   */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    key: string
  ) => {
    const { value, type, checked } = e.target;
    const newData = [...data];

    if (type === "checkbox") {
      newData[rowIndex][key] = checked;
    } else if (type === "number" && parseFloat(value) < 0) {
      return;
    } else {
      newData[rowIndex][key] = value;
      if (isLTL) {
        const [linearFeet, freightClass] = calculateLinearFeet(
          newData[rowIndex]
        );
        newData[rowIndex]["linearFeet"] = linearFeet;
        newData[rowIndex]["itemClass"] = freightClass;
      }
    }

    if (key === "amount") {
      checkAmountChange && checkAmountChange(true);
    } else {
      checkAmountChange && checkAmountChange(false);
    }

    setHasChanges && setHasChanges(true);
    newData[rowIndex].isModified = true;
    setData(newData);
  };

  const calculateLinearFeet = (rowData: Data): number[] => {
    let val = 0;
    if (rowData?.itemDimension?.length != "" && rowData?.handlingUnit != "") {
      if (rowData?.itemDimension?.width > 48) {
        val = (rowData.itemDimension.length * rowData.handlingUnit) / 12;
      } else {
        val = (rowData.itemDimension.length * rowData.handlingUnit) / 24;
      }
      const num = rowData.handlingUnit + 0;
      if (
        (rowData.stackable === "Yes" || rowData.stackable.id === "Yes") &&
        num > 10
      ) {
        val = val / 2;
      } else {
        val = val;
      }
    }
    let classVal = 500;
    if (
      rowData?.itemDimension?.length != "" &&
      rowData?.itemDimension?.width != "" &&
      rowData?.itemDimension?.height != "" &&
      rowData?.handlingUnit != "" &&
      rowData?.weight != ""
    ) {
      classVal = calculateFrightClass(rowData);
    }

    return [parseFloat(val.toFixed(2)), classVal];
  };

  const calculateFrightClass = (rowData: Data) => {
    const desnsity =
      (rowData.itemDimension.length *
        rowData.itemDimension.width *
        rowData.itemDimension.height) /
      1728;
    const linearFeet = rowData.weight / desnsity / rowData.handlingUnit;
    let freightclass = 0;
    if (linearFeet >= 0 && linearFeet < 1) {
      freightclass = 500;
    } else if (linearFeet > 1 && linearFeet <= 2) {
      freightclass = 400;
    } else if (linearFeet > 2 && linearFeet <= 3) {
      freightclass = 300;
    } else if (linearFeet > 3 && linearFeet <= 4) {
      freightclass = 250;
    } else if (linearFeet > 4 && linearFeet <= 5) {
      freightclass = 200;
    } else if (linearFeet > 5 && linearFeet <= 6) {
      freightclass = 175;
    } else if (linearFeet > 6 && linearFeet <= 7) {
      freightclass = 150;
    } else if (linearFeet > 7 && linearFeet <= 8) {
      freightclass = 125;
    } else if (linearFeet > 8 && linearFeet <= 9) {
      freightclass = 110;
    } else if (linearFeet > 9 && linearFeet <= 10.5) {
      freightclass = 100;
    } else if (linearFeet > 10.5 && linearFeet <= 12) {
      freightclass = 92.5;
    } else if (linearFeet > 12 && linearFeet <= 13.5) {
      freightclass = 85;
    } else if (linearFeet > 13.5 && linearFeet <= 15) {
      freightclass = 77.5;
    } else if (linearFeet > 15 && linearFeet <= 22.5) {
      freightclass = 70;
    } else if (linearFeet > 22.5 && linearFeet <= 30) {
      freightclass = 65;
    } else if (linearFeet > 30 && linearFeet <= 35) {
      freightclass = 60;
    } else if (linearFeet > 35 && linearFeet <= 50) {
      freightclass = 55;
    } else if (linearFeet > 50) {
      freightclass = 50;
    }
    return freightclass;
  };
  /**
   * Handles the change event for a dropdown in a table row.
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} e - The change event triggered by selecting a dropdown option.
   * @param {number} rowIndex - The index of the row being updated.
   * @param {string} key - The key of the column where the dropdown is located.
   */
  const handleDropdownChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    rowIndex: number,
    key: string
  ) => {
    e.stopPropagation();
    const { value } = e.target;
    const newData = [...data];
    const selectedOption = props.columns
      .find((col) => col.key === key)
      ?.dropdownData?.find((option: any) => option.id === value);
    // Update the data with the selected dropdown value
    if (selectedOption) {
      if (key === "packageType" || key === "itemClass") {
        newData[rowIndex][key] = selectedOption.name;
      } else {
        newData[rowIndex][key] = {
          name: selectedOption.name,
          id: selectedOption.id,
        };
      }
      if (isLTL && key != "itemClass" && key != "packageType") {
        const [linearFeet, freightclass] = calculateLinearFeet(
          newData[rowIndex]
        );
        newData[rowIndex]["linearFeet"] = linearFeet;
        newData[rowIndex]["itemClass"] = freightclass;
      }
    }

    setHasChanges && setHasChanges(true);
    newData[rowIndex].isModified = true;
    setData(newData);
  };

  /**
   * Handles checkbox change for a row.
   * When checked, sets isModified to true, when unchecked, sets it to false.
   * @param rowIndex - Index of the row being modified.
   */
  const handleCheckboxChange = (rowIndex: number) => {
    const newData = [...data];
    const row = newData[rowIndex];
    row.selected = !row.selected;
    row.isModified = row.selected; // If selected, mark as modified, otherwise unmark
    setData(newData);
    setHasChanges && setHasChanges(true);
    checkAmountChange && checkAmountChange(true);
  };

  const handleHazmatCheckboxChange = (rowIndex: number) => {
    const newData = [...data];
    newData[rowIndex]["hazmat"] = !newData[rowIndex]["hazmat"];
    setData(newData);
    setHasChanges && setHasChanges(true);
  };

  /**
   * Handles "Select All" checkbox change.
   * When clicked, either selects or deselects all rows.
   */
  const handleSelectAllChange = (
    newStatus: boolean,
    setHasChanged: boolean
  ) => {
    const newSelectAllChecked = newStatus;
    setSelectAllChecked(newSelectAllChecked);
    const updatedData = data.map((row) => ({
      ...row,
      selected: newSelectAllChecked,
      isModified: newSelectAllChecked, // Mark all rows as modified when selected
    }));
    setData(updatedData);
    if (setHasChanged) {
      setHasChanges && setHasChanges(true);
    }
    checkAmountChange && checkAmountChange(true);
  };

  /**
   * Handles changes in child input fields for nested column data.
   *
   * @param e - The input change event.
   * @param rowIndex - Index of the row being edited.
   * @param parentKey - Key of the parent column.
   * @param childKey - Key of the child column.
   */
  const handleChildInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    parentKey: string,
    childKey: string
  ) => {
    const { value, type, checked } = e.target;
    const newData = [...data];

    if (type === "checkbox") {
      newData[rowIndex][parentKey][childKey] = checked;
    } else if (type === "number" && parseFloat(value) < 0) {
      return;
    } else {
      newData[rowIndex][parentKey][childKey] = value;
      if (isLTL) {
        const [linearFeet, freightClass] = calculateLinearFeet(
          newData[rowIndex]
        );
        newData[rowIndex]["linearFeet"] = linearFeet;
        newData[rowIndex]["itemClass"] = freightClass;
      }
    }
    newData[rowIndex].isModified = true;
    setData(newData);
  };

  /**
   * Handles row deletion. Shows a confirmation modal for rows with an ID.
   *
   * @param e - The click event for the delete button.
   * @param rowIndex - Index of the row to delete.
   * @param row - Data of the row to perform the action on.
   */
  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    rowIndex: number,
    row: any
  ) => {
    e.stopPropagation(); // Prevent triggering row click
    if (row.id === "" || deleteFromDataOnEdit) {
      const newData = data.filter((_, index) => index !== rowIndex);
      // Update count values
      newData.forEach((item, idx) => {
        item.count = idx + 1;
      });
      setData(newData);
      setHasChanges && setHasChanges(true);
      checkAmountChange && checkAmountChange(true);
    } else {
      handleAction(
        row,
        "Delete",
        onDeleteConfig?.heading || "Delete!",
        `delete ${onDeleteConfig?.para}`,
        onDeleteConfig?.successMessage || ""
      );
    }
  };

  /**
   * Handles row edit
   *
   * @param e - The click event for the edit button.
   * @param rowIndex - Index of the row to delete.
   * @param row - Data of the row to perform the action on.
   */
  const handleEditRow = (
    e: React.MouseEvent<HTMLButtonElement>,
    rowIndex: number,
    row: any
  ) => {
    e.stopPropagation(); // Prevent triggering row click
    if (handleEditModalOpen) {
      handleEditModalOpen(row);
    }
  };

  /**
   * Opens the modal for editing hazmat details.
   *
   * @param e - The click event for the edit button.
   * @param rowIndex - Index of the row being edited.
   * @param row - Data of the row to perform the action on.
   */
  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    rowIndex: number,
    row: any
  ) => {
    e.stopPropagation();
    setRowData(row);
    setRowIndex(rowIndex);
    setAddHazmatDetailsModal(true);
  };

  /**
   * Confirms and performs the delete action.
   */
  const handleConfirmAction = async () => {
    if (!rowData || !onDeleteConfig) return;
    setLoading(true);
    try {
      let apiUrl = `${onDeleteConfig.apiUrl}${rowData[onDeleteConfig.urlID]}`;

      const response = await deleteApi(apiUrl);
      if (response.success) {
        setConfirmModal(false);
        setMessage(confirmSuccessText, "success");
        getApiCall?.();
        setHasChanges && setHasChanges(true);
        // close modal
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Calculates totals for handling units, weight, and pieces.
   *
   * @param data - The table data.
   * @returns An object containing the totals for handling units, weight, and pieces.
   */
  const calculateTotals = (data: Data[]) => {
    let totalHandlingUnits = 0;
    let totalWeight = 0;
    let totalPieces = 0;
    let totalFeet = 0;

    data?.forEach((item) => {
      const handlingUnits = parseFloat(item?.handlingUnit) || 0;
      const quantity = parseFloat(item?.quantity) || 0;
      const weight = parseFloat(item?.weight) || 0;
      const linearFeet = parseFloat(item?.linearFeet) || 0;
      const itemWeight = weight;

      totalHandlingUnits += handlingUnits;
      totalWeight += itemWeight;
      totalPieces += quantity;
      totalFeet += linearFeet;
    });

    return {
      handlingUnits: totalHandlingUnits,
      weight: totalWeight,
      pieces: totalPieces,
      totalFeet,
    };
  };

  /**
   * Calculates totals for handling units, weight, and pieces whenever `data` changes.
   * Updates the `setFields` state with the calculated totals.
   *
   * @effect Runs when `data` prop changes.
   */
  useEffect(() => {
    const totals = calculateTotals(data);
    setFields &&
      setFields((prev: any) => ({
        ...prev,
        destinationHandingUnits: totals.handlingUnits,
        destinationWeight: totals.weight,
        destinationPieces: totals.pieces,
        totalLinearFeet: totals.totalFeet,
      }));
    if (selectAllCheckboxes && data?.length && checkFirstRender) {
      setCheckFirstRender(false);
      handleSelectAllChange(true, false);
    }
  }, [data]);

  /**
   * Sorts the data array by `serial` property when no row is being edited.
   * Ensures the data is sorted correctly if `editingRow` is set to `null`.
   *
   * @effect Runs when `editingRow` state changes.
   */
  useEffect(() => {
    if (editingRow === null) {
      const sortedData = [...data].sort((a, b) => a.serial - b.serial);
      setData(sortedData);
    }
  }, [editingRow]);

  return (
    <>
      <div className="tableContainer" ref={tableRef}>
        <table className="table">
          <thead>
            <tr>
              {columns?.map((column) => (
                <th key={column.key}>
                  {column.className === "count" ? (
                    column.header
                  ) : column.checkbox ? (
                    <input
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={() =>
                        handleSelectAllChange(!selectAllChecked, true)
                      }
                    />
                  ) : column.className === "threeRow" ? (
                    <>
                      <div className="sortingDiv sortingDiv--center">
                        {column.header}{" "}
                        {column?.mandatory && (
                          <span className="mandatory-mark">*</span>
                        )}
                      </div>
                      <div className="sortingDiv sortingDiv--Rowthree">
                        {column.children?.map((item) => (
                          <span className="item" key={item.key}>
                            {item.header}
                          </span>
                        ))}
                      </div>
                    </>
                  ) : column?.subText ? (
                    <div className="cellText cellText--center">
                      <div className="sortingDiv sortingDiv--center"
                        onClick={() =>
                          column.sortable && requestSort(column.key)
                        }
                      >
                        {column.header}
                        <span className="sortingDiv__icon">
                        {getSortIcon(column.key)}
                        </span>
                      </div>
                      <span className="cellText__subText">
                        {column.subText}
                      </span>
                    </div>
                  ) : (
                    <div 
                      className="sortingDiv"
                      onClick={() =>
                        column.sortable && requestSort(column.key)
                      }
                    >
                      {column.header}
                      {column?.mandatory && (
                        <span className="mandatory-mark">*</span>
                      )}
                      {column.sortable && (
                        <span className="sortingDiv__icon">
                          {getSortIcon(column.key)}
                        </span>
                      )}
                    </div>
                  )}
                </th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {sortedData?.length === 0 ? (
              <tr>
                <td colSpan={columns.length + 1}>
                  <div className="no-data floating">No Results Found!</div>
                </td>
              </tr>
            ) : (
              sortedData?.map((row, rowIndex) => (
                <tr key={rowIndex} onClick={(e) => handleRowClick(e, rowIndex)}>
                  {columns.map((column) => (
                    <td key={column.key}>
                      {column?.className === "count" ? (
                        <div className="cellText">
                          <span className="cellText__dark cellText__dark--gray">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.checkbox ? (
                        <input
                          type="checkbox"
                          checked={!!row.selected}
                          onChange={() => handleCheckboxChange(rowIndex)}
                        />
                      ) : column.className === "threeRow" ? (
                        <>
                          <div className="cellText">
                            <div className="sortingDiv sortingDiv--Rowthree">
                              {editingRow !== rowIndex
                                ? column?.children?.map((child) => (
                                    <span className="item" key={child?.key}>
                                      {row?.[column.key]?.[child.key]}
                                    </span>
                                  ))
                                : column?.children?.map((child) => (
                                    <span className="item" key={child.key}>
                                      <input
                                        className="form__input form__input--active"
                                        type={child.type || "text"}
                                        value={row[column.key][child.key]}
                                        onChange={(e) =>
                                          handleChildInputChange(
                                            e,
                                            rowIndex,
                                            column.key,
                                            child.key
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </span>
                                  ))}
                            </div>
                          </div>
                        </>
                      ) : column.className === "hazmat" ? (
                        <>
                          <div className="cellText">
                            <div className="form__checkbox form__checkbox--gray">
                              <div className="form__checkbox__option">
                                <input
                                  type="checkbox"
                                  id="hazmat"
                                  className="form__checkbox__input"
                                  checked={row[column.key]}
                                  onChange={(e) => {
                                    if (row[column.key]) {
                                      if (!isEditable) {
                                        return;
                                      }
                                      handleHazmatCheckboxChange(rowIndex);
                                      return;
                                    } else {
                                      setRowData(row);
                                      setRowIndex(rowIndex);
                                      setAddHazmatDetailsModal(true);
                                    }
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <label
                                  className="form__checkbox__label"
                                  htmlFor="hazmat"
                                >
                                  &nbsp;
                                </label>
                              </div>
                              <div className="form__checkbox__option">
                                <button
                                  className="btn p_0 border_0"
                                  onClick={(e) => handleEdit(e, rowIndex, row)}
                                >
                                  <EditPenIcon />
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : column?.isActive ? (
                        row[column.key] ? (
                          <button className="btn p_0 border_0">
                            <ActiveIcon />
                          </button>
                        ) : (
                          <button className="btn p_0 border_0">
                            <UnactiveIcon />
                          </button>
                        )
                      ) : (
                        <>
                          {editingRow !== rowIndex || column.editColumn ? (
                            column.dropdown ? (
                              <div className="cellText">
                                <span className="cellText__dark">
                                  {row[column.key]?.name || row[column.key]}
                                </span>
                              </div>
                            ) : (
                              <div
                                className={`${
                                  CenterClassColumnKey?.includes(column.key)
                                    ? "cellText cellText--center"
                                    : "cellText"
                                }`}
                              >
                                <span className="cellText__dark">
                                  {column?.dolloar
                                    ? `$${Number(row[column.key])?.toFixed(2)}`
                                    : row[column.key]}
                                </span>
                              </div>
                            )
                          ) : column.dropdown ? (
                            <select
                              ref={
                                column.key === "packageType"
                                  ? selectRefPackageType
                                  : column.key === "itemClass"
                                  ? selectRefClass
                                  : column.key === "unit"
                                  ? selectRefUnit
                                  : selectRefSTackable
                              }
                              name={column.key}
                              value={
                                row[column.key]?.id || row[column.key] || ""
                              }
                              onChange={(e) => {
                                handleDropdownChange(e, rowIndex, column.key);
                              }}
                            >
                              {column.key === "packageType" ? (
                                <option key="0" value="" disabled>
                                  --Select--
                                </option>
                              ) : (
                                ""
                              )}
                              {column.dropdownData?.map((option: any) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <div className="cellText">
                              <input
                                type={column.type || "text"}
                                className="form__input form__input--active"
                                value={row[column.key]}
                                onChange={(e) =>
                                  handleInputChange(e, rowIndex, column.key)
                                }
                                disabled={
                                  editingRow !== rowIndex ||
                                  column.className === "disabled"
                                }
                                onClick={(e) => e.stopPropagation()}
                                maxLength={
                                  column.key === "comment" ? 50 : 524288
                                }
                                onKeyDown={(e) => handleKeyDown(e, column.type)}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  ))}

                  <td>
                    <div className="actions flex--center">
                      {showDelete && (
                        <button
                          className="btn p_0 border_0"
                          onClick={(e) => handleDelete(e, rowIndex, row)}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                      {editIcon && (
                        <button
                          className="btn p_0 border_0"
                          onClick={(e) => {
                            handleEditRow(e, rowIndex, row);
                          }}
                        >
                          <EditUserIcon />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {confirmModal && (
        <ConfirmModal
          confirmInfo={confirmText}
          onConfirm={handleConfirmAction}
          setConfirmModal={setConfirmModal}
        />
      )}
      {addHazmatDetailsModal && (
        <AddHazmatDetailsModal
          setConfirmModal={setAddHazmatDetailsModal}
          data={data}
          setData={setData}
          rowData={rowData}
          rowIndex={rowIndex}
        />
      )}
    </>
  );
};

export default ItemDetailsTable;
