import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, CustomerLevel } from "./authTypes";

import {
  clearTokenFromLocalStorage,
  CUSTOMER_ID,
  CUSTOMER_LEVEL,
  CUSTOMER_NAME,
  EMAIL_KEY,
  IS_CUSTOMER_KEY,
  IS_DIRECT_CUTOMER,
  IS_USER_OF_DIRECT_CUSTOMER,
  IS_USER_OF_OMNI_MOVE,
  ROLES_KEY,
  TOKEN_EXPIRATION_KEY,
  TOKEN_KEY,
  PARENT_CUSTOMER_ID,
  AGENT_ID
} from "../../../utils/authUtils";

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem(TOKEN_KEY),
  userRole: localStorage.getItem(ROLES_KEY) ?? null,
  token: localStorage.getItem(TOKEN_KEY) ?? null,
  email: localStorage.getItem(EMAIL_KEY) ?? null,
  isCustomer:
    localStorage.getItem(IS_CUSTOMER_KEY) === "false" ? false : true || false,
  isUserOfOmniMove:
    localStorage.getItem(IS_USER_OF_OMNI_MOVE) === "false"
      ? false
      : true || false,
  customerId: localStorage.getItem(CUSTOMER_ID) ?? null,
  customerName: localStorage.getItem(CUSTOMER_NAME) ?? null,
  isDirectCustomer:
    localStorage.getItem(IS_DIRECT_CUTOMER) === "false" ? false : true || false,
  isUserOfDirectCustomer:
    localStorage.getItem(IS_USER_OF_DIRECT_CUSTOMER) === "false"
      ? false
      : true || false,
  customerLevel:
    (localStorage.getItem(CUSTOMER_LEVEL) as CustomerLevel) || null,
  agentId: localStorage.getItem(AGENT_ID) ?? null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (
      state,
      action: PayloadAction<{
        userRole: string;
        token: string;
        email: string;
        expiration: number;
        customer: any;
        parentCustomerId: string;
        agent: any;
      }>
    ) => {
      const { userRole, token, email, expiration, customer, parentCustomerId, agent } = action.payload;
      state.isAuthenticated = true;
      state.userRole = userRole;
      state.token = token;
      state.email = email;
      localStorage.setItem(TOKEN_KEY, token);
      localStorage.setItem(EMAIL_KEY, email);
      localStorage.setItem(ROLES_KEY, userRole);
      // Store expiration timestamp
      localStorage.setItem(TOKEN_EXPIRATION_KEY, expiration?.toString());
      // customer id
      if (customer?.id) {
        state.customerId = customer?.id || "";
        state.customerName = customer?.name || "";
        localStorage.setItem(CUSTOMER_ID, customer?.id);
        localStorage.setItem(CUSTOMER_NAME, customer?.name);
      }
      if(agent?.id){
        state.agentId = agent?.id || "";
        localStorage.setItem("agentId", agent?.id);
      }
      localStorage.setItem(PARENT_CUSTOMER_ID, parentCustomerId);
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.userRole = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem(TOKEN_KEY, action.payload);
    },
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.userRole = null;
      state.token = null;
      state.email = null;
      clearTokenFromLocalStorage();
    },
    setIsCUSTOMER: (state, action: PayloadAction<boolean>) => {
      state.isCustomer = action.payload;
      localStorage.setItem(IS_CUSTOMER_KEY, action.payload?.toString());
    },
    setIsUserOfOmniMove: (state, action: PayloadAction<boolean>) => {
      state.isUserOfOmniMove = action.payload;
      localStorage.setItem(IS_USER_OF_OMNI_MOVE, action.payload?.toString());
    },
    setDirectCustomer: (
      state,
      action: PayloadAction<{
        isDirectCustomer: boolean;
        isUserOfDirectCustomer: boolean;
      }>
    ) => {
      const { isDirectCustomer, isUserOfDirectCustomer } = action.payload;
      state.isDirectCustomer = isDirectCustomer;
      state.isUserOfDirectCustomer = isUserOfDirectCustomer;
      localStorage.setItem(IS_DIRECT_CUTOMER, isDirectCustomer?.toString());
      localStorage.setItem(
        IS_USER_OF_DIRECT_CUSTOMER,
        isUserOfDirectCustomer?.toString()
      );
    },
    setCustomerLevel: (state, action: PayloadAction<CustomerLevel | null>) => {
      state.customerLevel = action.payload;
      localStorage.setItem(CUSTOMER_LEVEL, action.payload ?? "");
    },
  },
});

export const {
  loginUser,
  setRole,
  setToken,
  logoutUser,
  setIsCUSTOMER,
  setIsUserOfOmniMove,
  setDirectCustomer,
  setCustomerLevel,
} = authSlice.actions;

export default authSlice.reducer;
