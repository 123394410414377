import * as Yup from "yup";
import * as CONSTANTS from "../../utils/constants";
import { EmailRegex, isValidName } from "../../utils/regex";

export const addUserFormSchema = Yup.object().shape({
  userRole: Yup.string().required(CONSTANTS.ROLE.validation.required),
  firstName: Yup.string().required(CONSTANTS.FIRST_NAME.validation.required),
  //.matches(isValidName, CONSTANTS.FIRST_NAME.validation.valid),
  lastName: Yup.string().required(CONSTANTS.LAST_NAME.validation.required),
  //.matches(isValidName, CONSTANTS.LAST_NAME.validation.valid),
  email: Yup.string()
    .required(CONSTANTS.EMAIL.validation.required)
    .matches(EmailRegex, CONSTANTS.EMAIL.validation.valid),
  phoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("countryCode", {
      is: (countryCode) => countryCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) => s.min(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
});

export const profileFormSchema = Yup.object().shape({
  firstName: Yup.string().required(CONSTANTS.FIRST_NAME.validation.required),
  //.matches(isValidName, CONSTANTS.FIRST_NAME.validation.valid),
  lastName: Yup.string().required(CONSTANTS.LAST_NAME.validation.required),
  //.matches(isValidName, CONSTANTS.LAST_NAME.validation.valid),
  email: Yup.string()
    .required(CONSTANTS.EMAIL.validation.required)
    .email(CONSTANTS.EMAIL.validation.valid),
  phoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("countryCode", {
      is: (countryCode) => countryCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) => s.min(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string().required(CONSTANTS.PASSWORD.validation.required),
  newPassword: Yup.string()
    .required(CONSTANTS.PASSWORD.validation.required)
    .min(8, CONSTANTS.PASSWORD.validation.valid)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
      CONSTANTS.PASSWORD.validation.valid
    ),
  confirmPassword: Yup.string()
    .required(CONSTANTS.CONFIRM_PASSWORD.validation.required)
    .oneOf(
      [Yup.ref("newPassword"), null],
      CONSTANTS.CONFIRM_PASSWORD.validation.match
    ),
});

export const addCustomerUserFormSchema = Yup.object().shape({
  //userRole: Yup.string().required(CONSTANTS.ROLE.validation.required),
  firstName: Yup.string().required(CONSTANTS.FIRST_NAME.validation.required),
  lastName: Yup.string().required(CONSTANTS.LAST_NAME.validation.required),
  email: Yup.string()
    .required(CONSTANTS.EMAIL.validation.required)
    .matches(EmailRegex, CONSTANTS.EMAIL.validation.valid),
  phoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("countryCallingCode", {
      is: (countryCallingCode) => countryCallingCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) =>
        s
          .min(10, CONSTANTS.PHONENUMBER.validation.valid)
          .max(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
});

export const addCustomerFormSchema = Yup.object().shape({
  customerName: Yup.string()
    .required(CONSTANTS.CUSTOMERNAME.validation.required)
    .matches(isValidName, CONSTANTS.CUSTOMERNAME.validation.valid),
  emailAddress: Yup.string()
    .required(CONSTANTS.EMAIL.validation.required)
    .matches(EmailRegex, CONSTANTS.EMAIL.validation.valid),
  phoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("countryCallingCode", {
      is: (countryCallingCode) => countryCallingCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) => s.min(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
  addressContactName: Yup.string().required(
    CONSTANTS.CONTACT_PERSON.validation.required
  ),
  //phoneExt: Yup.string().required(CONSTANTS.PHONE_EXT.validation.required),
  address: Yup.string().required(CONSTANTS.ADDRESS.validation.valid),
  //aptSuite: Yup.string().required("Please enter APT/Suite name"),
  zipCode: Yup.string()
    .required(CONSTANTS.ZIP_CODE.validation.valid)
    .min(5, CONSTANTS.ZIP_CODE.validation.valid)
    .max(9, CONSTANTS.ZIP_CODE.validation.valid),
  customerPhoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("customerCountryCallingCode", {
      is: (customerCountryCallingCode) => customerCountryCallingCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) => s.min(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
  //customerPhoneExt: Yup.string().required(CONSTANTS.PHONE_EXT.validation.required),
  businessLocation: Yup.string().required("Please enter business location"),
  contactPerson: Yup.string().required(
    CONSTANTS.CONTACT_PERSON.validation.required
  ),
  contactEmail: Yup.array()
    .min(1, CONSTANTS.EMAIL.validation.required)
    .max(5, "Please enter only five contact email"),
  latitude: Yup.number()
    .min(-90, "Min latitude value should be -90")
    .max(90, "Max latitude value should be 90"),
  longitude: Yup.number()
    .min(-180, "Min longitude value should be -180")
    .max(180, "Max longitude value should be 180"),
  banyanDirectReference: Yup.string().when("worldwideExpress", {
    is: (worldwideExpress) => worldwideExpress === true,
    then: (s) => s.required(`Please enter banyan direct reference`),
  }),
  banyanDirectUser: Yup.string().when("worldwideExpress", {
    is: (worldwideExpress) => worldwideExpress === true,
    then: (s) => s.required(`Please enter banyan direct user`),
  }),
  banyanDirectPassword: Yup.string().when("worldwideExpress", {
    is: (worldwideExpress) => worldwideExpress === true,
    then: (s) => s.required(`Please enter banyan direct password`),
  }),
  banyanDirectURL: Yup.string().when("worldwideExpress", {
    is: (worldwideExpress) => worldwideExpress === true,
    then: (s) => s.required(`Please enter banyan direct url`),
  }),
  //operationHrsFrom: Yup.string().required("Please enter from operation hours"),
  // operationHrsTo: Yup.string().test(
  //   "valid-end-time",
  //   "To operation hrs is not less than from operation hrs.",
  //   function (value, { parent }) {
  //     const fromOperationHrs = parent.operationHrsFrom;
  //     const toOperationHrs = value;
  //     if (
  //       new Date(`02-03-2023 ${fromOperationHrs}`) >
  //       new Date(`02-03-2023 ${toOperationHrs}`)
  //     ) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // ),
});

export const addEditAgentFormSchema = Yup.object().shape({
  name: Yup.string().required(CONSTANTS.AGENT_NAME.validation.required),
  email: Yup.string()
    .required(CONSTANTS.EMAIL.validation.required)
    .matches(EmailRegex, CONSTANTS.EMAIL.validation.valid),
  phoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("countryCode", {
      is: (countryCode) => countryCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) => s.min(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
  agentType: Yup.string().required(CONSTANTS.AGENT_TYPE.validation.required),
  carrierCode: Yup.string().required("Please enter carrier code"),
});

export const addAgentHubFormSchema = Yup.object().shape({
  agentName: Yup.object().required("Please select agent name."),
  hubName: Yup.string().required(CONSTANTS.HUB_NAME.validation.required),
  phoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("countryCode", {
      is: (countryCode) => countryCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) => s.min(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
  operationEmailAddress: Yup.array()
    .min(1, CONSTANTS.EMAIL.validation.required)
    .max(5, "Please enter only five operational email")
    .test(
      "valid-contact-email",
      "Please enter valid operational email.",
      function (value, { parent }) {
        const emails = value;
        let emailStatus = true;
        emails.map((email) => {
          if (!EmailRegex.test(email)) {
            emailStatus = false;
          }
        });
        return emailStatus;
      }
    ),
  // activeDate: Yup.string()
  //   .test(
  //     "valid-active-date",
  //     "Active date should be greater than deactivate date.",
  //     function (value, { parent }) {
  //       const deactivate = parent.deactivateDate;
  //       const activeDate = value;
  //       if(deactivate === undefined && activeDate === undefined){
  //         return true;
  //       }
  //       return new Date(activeDate) <= new Date(deactivate);
  //     }
  //   ),
  deactivateDate: Yup.string().test(
    "valid-deactivate-date",
    "Deactivate date is not less than activate date.",
    function (value, { parent }) {
      const activeDate = parent.activeDate;
      const deactivate = value;
      if (deactivate === undefined || activeDate === undefined) {
        return true;
      }
      return new Date(activeDate) <= new Date(deactivate);
    }
  ),
  address: Yup.string().required(CONSTANTS.ADDRESS.validation.valid),
  //aptSuite: Yup.string().required("Please enter APT/Suite name"),
  zipCode: Yup.string()
    .required(CONSTANTS.ZIP_CODE.validation.valid)
    .min(5, CONSTANTS.ZIP_CODE.validation.valid)
    .max(9, CONSTANTS.ZIP_CODE.validation.valid),
  hubPhoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("hubCountryCode", {
      is: (hubCountryCode) => hubCountryCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) => s.min(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
  businessLocation: Yup.string().required("Please enter business location"),
  contactName: Yup.string().required(
    CONSTANTS.CONTACT_PERSON.validation.required
  ),
  contactEmail: Yup.array()
    .min(1, CONSTANTS.EMAIL.validation.required)
    .max(5, "Please enter only five contact email")
    .test(
      "valid-contact-email",
      "Please enter valid contact email.",
      function (value, { parent }) {
        const emails = value;
        let emailStatus = true;
        emails.map((email) => {
          if (!EmailRegex.test(email)) {
            emailStatus = false;
          }
        });
        return emailStatus;
      }
    ),
  latitude: Yup.number()
    .min(-90, "Min latitude value should be -90")
    .max(90, "Max latitude value should be 90"),
  longitude: Yup.number()
    .min(-180, "Min longitude value should be -180")
    .max(180, "Max longitude value should be 180"),
});

// export const orderStatusSchema = Yup.object().shape({
//   agent: Yup.object().required(CONSTANTS.AGENT_NAME.validation.required),
//   orderStatus: Yup.object().required(
//     CONSTANTS.ORDER_STATUS.validation.required
//   ),
//   statusLocation: Yup.string().required(
//     CONSTANTS.STATUS_LOCATION.validation.required
//   ),
//   zipCode: Yup.string()
//     .required(CONSTANTS.ZIP_CODE.validation.required)
//     .min(5, CONSTANTS.ZIP_CODE.validation.valid)
//     .max(9, CONSTANTS.ZIP_CODE.validation.valid),
//   statusTime: Yup.string().required(CONSTANTS.STATUS_TIME.validation.required),
//   statusDate: Yup.string().required(CONSTANTS.STATUS_DATE.validation.required),
//   // statusNote: Yup.string().required(CONSTANTS.STATUS_NOTE.validation.required),
// });
export const orderStatusSchema = Yup.object().shape({
  agent: Yup.object().required(CONSTANTS.AGENT_NAME.validation.required),
  orderStatus: Yup.object().required(
    CONSTANTS.ORDER_STATUS.validation.required
  ),
  statusLocation: Yup.string().optional(),
  zipCode: Yup.string()
    .optional()
    .min(5, CONSTANTS.ZIP_CODE.validation.valid)
    .max(9, CONSTANTS.ZIP_CODE.validation.valid),
  statusTime: Yup.string().optional(),
  statusDate: Yup.string().optional(),
  // statusNote: Yup.string().required(CONSTANTS.STATUS_NOTE.validation.required),
});

export const xceleratorApiSchema = Yup.object().shape({
  agentName: Yup.object({
    id: Yup.string().required(CONSTANTS.AGENT_NAME.validation.required),
  }),
  hubName: Yup.object({
    id: Yup.string().required(CONSTANTS.HUB_NAME.validation.required),
  }),
  apiUrl: Yup.string().required(CONSTANTS.API_URL.validation.required),
  apiAccountNo: Yup.string().required(
    CONSTANTS.API_ACCOUNT_NO.validation.required
  ),
  apiLogin: Yup.string().required(CONSTANTS.API_LOGIN.validation.required),
  apiPassword: Yup.string().required(
    CONSTANTS.API_PASSWORD.validation.required
  ),
  startDate: Yup.string().required(CONSTANTS.START_DATE.validation.required),
});

export const xceleratorApiServiceSchema = Yup.object().shape({
  serviceName: Yup.string().required(CONSTANTS.API_URL.validation.required),
  serviceCode: Yup.string().required(
    CONSTANTS.X_SERVICE_CODE.validation.required
  ),
  serviceId: Yup.string().required(CONSTANTS.X_SERVICE_ID.validation.required),
  serviceDuration: Yup.string().required(
    CONSTANTS.X_SERVICE_DURATION.validation.required
  ),
  details: Yup.string().required(
    CONSTANTS.X_SERVICE_DETAILS.validation.required
  ),
});

export const operationalAddressSchema = Yup.object().shape({
  address: Yup.string().required(CONSTANTS.ADDRESS.validation.valid),
  //aptSuite: Yup.string().required("Please enter APT/Suite name"),
  zipCode: Yup.string()
    .required(CONSTANTS.ZIP_CODE.validation.valid)
    .min(5, CONSTANTS.ZIP_CODE.validation.valid)
    .max(9, CONSTANTS.ZIP_CODE.validation.valid),
  phoneNumber: Yup.string()
    .required(CONSTANTS.PHONENUMBER.validation.required)
    .when("hubCountryCode", {
      is: (hubCountryCode) => hubCountryCode === "+1",
      then: (s) => s.min(16, CONSTANTS.PHONENUMBER.validation.valid),
      otherwise: (s) => s.min(10, CONSTANTS.PHONENUMBER.validation.valid),
    }),
  businessLocation: Yup.string().required("Please enter business location"),
  contactName: Yup.string().required(
    CONSTANTS.CONTACT_PERSON.validation.required
  ),
  contactEmail: Yup.array()
    .min(1, CONSTANTS.EMAIL.validation.required)
    .max(5, "Please enter only five contact email")
    .test(
      "valid-contact-email",
      "Please enter valid contact email.",
      function (value, { parent }) {
        const emails = value;
        let emailStatus = true;
        emails.map((email) => {
          if (!EmailRegex.test(email)) {
            emailStatus = false;
          }
        });
        return emailStatus;
      }
    ),
  latitude: Yup.number()
    .min(-90, "Min latitude value should be -90")
    .max(90, "Max latitude value should be 90"),
  longitude: Yup.number()
    .min(-180, "Min longitude value should be -180")
    .max(180, "Max longitude value should be 180"),
  //operationHrsFrom: Yup.string().required("Please enter from operation hours"),
  //operationHrsTo: Yup.string()
  //.required("Please enter to operation hours")
  // .test(
  //   "valid-end-time",
  //   "To operation hrs is not less or equal to the from operation hrs.",
  //   function (value, { parent }) {
  //     const fromOperationHrs = parent.operationHrsFrom;
  //     const toOperationHrs = value;
  //     if (
  //       new Date(`02-03-2023 ${fromOperationHrs}`) >=
  //       new Date(`02-03-2023 ${toOperationHrs}`)
  //     ) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // ),
});
