// Constants for Messages
export const OTP_SENT_SUCCESS = "OTP resent successfully!";
export const OTP_PASTE_ERROR = "Please paste digits.";
export const GENERAL_ERROR_MESSAGE = "Something went wrong, Please try again.";
export const INVALID_URL_ERROR = "Invalid URL. Allowed extensions: .doc, .docx, .pdf, .txt";
export const DUPLICATE_URL_ERROR = "URL already exist";
export const API_KEY_CREATED = "API key created successfully!";
export const API_KEY_REMOVED = "API key removed successfully!";

// Password Messages
export const PASSWORD_VALIDATION_ERROR =
  "Password must be 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character.";
export const PASSWORD_MISMATCH_ERROR =
  "Password and Re-enter password do not match.";
export const SUCCESS_PASSWORD_CREATED = "Password created successfully!";
export const PASSWORD_UPDATED_CREATED = "Password updated successfully!";
export const CURRENT_PASSWORD_MATCH_ERROR =
  "Current password and New password should not be same.";
export const NEW_PASSWORD_MATCH_ERROR =
  "New password and Confirm password does not match.";

// Hazmat modal Messages
export const Hazmat_Message = {
  SUCCESS_ADD: "Hazmat added successfully!",
  SUCCESS_UPDATE: "Hazmat updated successfully!",
};

// Add note modal Messages
export const Add_Note_Messages = {
  SUCCESS_ADD: "Order note added successfully!",
  SUCCESS_UPDATE: "Order note updated successfully!",
  SUCCESS_DELETE: "Order note deleted successfully!",
};

// Order status modal Messages
export const ORDER_STATUS_MESSAGES = {
  SUCCESS_ADD: "Order status added successfully!",
  SUCCESS_UPDATE: "Order status updated successfully!",
};

// Add Xcalarator Package modal Messages
export const ADD_XCELERATOR_MESSAGES = {
  SUCCESS_ADD: "Package added successfully!",
  SUCCESS_UPDATE: "Package updated successfully!",
};

// Xcalarator service modal Messages
export const XCELERATOR_SERVICE_MESSAGES = {
  SUCCESS_ADD: "Xcelerator API created successfully!",
  SUCCESS_UPDATE: "Xcelerator API updated successfully!",
};
// Xcelerator Vehicle modal Messages
export const XCELERATOR_VECHILE_MESSAGES = {
  SUCCESS_ADD: "Vehicle added successfully!",
  SUCCESS_UPDATE: "Vehicle updated successfully!",
};

// Agent Profile Messages
export const AGENT_PROFILE_MESSAGES = {
  SUCCESS_UPDATE: "Agent Profile updated successfully!",
};

// User Profile Messages
export const USER_PROFILE_MESSAGES = {
  SUCCESS_UPDATE: "Profile updated successfully!",
  PHONE_UPDATE: "Phone Number updated",
};

// User Profile Messages
export const COMPANY_PROFILE_MESSAGES = {
  SUCCESS_UPDATE: "Company Profile updated successfully!",
};

// User Add/Edit Messages
export const USER_ADDEDIT_PROFILE_MESSAGES = {
  SUCCESS_UPDATE: "User updated successfully!",
  SUCCESS_ADD: "User added successfully!",
};

// Customer Add/Edit Messages
export const CUSTOMER_ADDEDIT_PROFILE_MESSAGES = {
  SUCCESS_UPDATE: "Customer user updated successfully!",
  SUCCESS_ADD: "Customer user added successfully!",
};

// CustomerS Add/Edit Messages
export const CUSTOMERS_ADD_MESSAGES = {
  SUCCESS_UPDATE: "Customer updated successfully!",
  SUCCESS_ADD: "Customer created successfully!",
  IMAGE_UPLOAD_ERROR: "Please upload less than 2MB image.",
  IMAGE_UPLOAD_VALIDATION: "Please upload JPEG/JPG/PNG file.",
  OPERATIONAL_ADDRESS_UPDATED: "Operational Information updated successfully!",
};

export const BOL_DOWNLOAD_SUCCESS = "BOL PDF has been downloaded successfully!";

// Xcelerator Api Messages
export const XCELERATOR_ADD_API_MESSAGES = {
  SUCCESS_UPDATE: "Xcelerator api updated successfully!",
  SUCCESS_ADD: "Xcelerator api created successfully!",
};

export const SWITCH_CUSTOMER_MESSAGES = {
  SUCCESS_CHANGE: "Customer switched successfully!",
};

export const CUSTOMER_MARKUP_MESSAGES = {
  SUCCESS_UPDATE: "Customer Markup Saved Successfully!",
  SUCCESS_ADD: "Customer markup created successfully!",
};

//  Invoice Group
export const CUSTOMER_INVOICE_GROUP_MESSAGES = {
  SUCCESS_UPDATE: "Customer invoice group updated successfully!",
  SUCCESS_ADD: "Customer invoice group added successfully!",
};

export const ORDER_INVOICE_GROUP_MESSAGES = {
  SUCCESS_UPDATE: "Order invoice updated successfully!",
  SUCCESS_ADD: "Order invoice added successfully!",
};

export const EMAIL_MESSAGES = {
  OPERATIONAL_EMAIL_EXIST: "Operational email already exist.",
  CONTACT_EMAIL_EXIST: "Contact email already exist.",
};

export const CUSTOMER_ADDITIONAL_CHARGES_MESSAGES = {
  SUCCESS_UPDATE: "Customer Additional Charges updated successfully!",
  SUCCESS_ADD: "Customer Additional Charges added successfully!",
};
export const AGENT_ADDITIONAL_CHARGES_MESSAGES = {
  SUCCESS_UPDATE: "Agent Additional Charges updated successfully!",
  SUCCESS_ADD: "Agent Additional Charges added successfully!",
};
