import { EmailRegex, NumberRegx, contactPersonRegex } from "./regex";

/**
 * Validation function for phone numbers.
 *
 * @param {string} value - The phone number to be validated.
 * @returns {string} - An error message if the phone number is invalid, otherwise an empty string.
 */
export const validatePhoneNumber = (value: string): string => {
  if (!/^\d{10}$/.test(value)) {
    return "Please enter valid phone number.";
  }
  return "";
};

/**
 * Validation function for masked phone numbers based on country calling code.
 *
 * @param {string} value - The masked phone number to be validated.
 * @param {string} countryCallingCode - The country calling code (e.g., "+1", "+91").
 * @returns {string} - An error message if the masked phone number is invalid, otherwise an empty string.
 */
export const validateMaskedPhoneNumber = (
  value: string,
  countryCallingCode: string
): string => {
  if (
    (countryCallingCode === "+1" && value?.length < 16) ||
    (countryCallingCode === "+91" && (value?.length < 10 || value?.length > 10))
  ) {
    return "Please enter valid phone number.";
  }
  return "";
};

/**
 * Validation function for zip codes.
 *
 * @param {string} value - The zip code to be validated.
 * @returns {string} - An error message if the zip code is invalid, otherwise an empty string.
 */
export const validateZipCode = (value: string): string => {
  if (value && value.length < 5 || value.length > 9) {
    return "Please enter a valid zip code.";
  }
  return "";
};

/**
 * Validation function for contact email addresses.
 *
 * @param {string} value - The email address to be validated.
 * @returns {string} - An error message if the email address is invalid, otherwise an empty string.
 */
export const validateContactEmail = (value: string): string => {
  if (!EmailRegex.test(value)) {
    return "Please enter a valid email address.";
  }
  return "";
};

export const ValidateTime = (startTime: string, endTime: string): string =>{
    const parseTime = (time : string): number => {
      const [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);
      return hours * 60 + minutes; // Return total minutes
    };
    const totalMinutes1 = parseTime(startTime);
    const totalMinutes2 = parseTime(endTime);

    if (totalMinutes1 > totalMinutes2) {
      return "Open Time cannot be greater than close time";
    } else {
      return "";
    }
}


export const ValidateDate = (date1: string, message?: string, date2?: string): string =>{
  let dt = new Date(date1);
  let dt2 = date2 ? new Date(date2) : new Date();

  if (dt.getTime() < dt2.getTime()) {
    return message ?? "Pickup Date cannot be earlier than today.";
  } else {
    return "";
  }
}

export const ValidateExpedietedDate = (date1: string, message?: string, date2?: string): string =>{
  let dt = new Date(date1);
  let dt2 = date2 ? new Date(date2) : new Date();
  if (dt.getTime() < dt2.getTime()) {
    return message ?? "test fail";
  } else {
    return "";
  }
}

export const ValidateExpedietedCrrentTime = (date1: string, message?: string, date2?: string): string =>{
  let dt = new Date(date1);
  let dt2 = date2 ? new Date(date2) : new Date();
  if (dt.getTime() < new Date().getTime()) {
    return message ?? "Given date and time should be greater than current date and time";
  } else {
    return "";
  }
}

/**
 * Validation function for latitude values.
 *
 * @param {number} value - The latitude value to be validated.
 * @returns {string} - An error message if the latitude is out of bounds, otherwise an empty string.
 */
export const validateLatitude = (value: number): string => {
  if (value < -90 || value > 90) {
    return "Latitude must be between -90 and 90 degrees.";
  }
  return "";
};

/**
 * Validation function for longitude values.
 *
 * @param {number} value - The longitude value to be validated.
 * @returns {string} - An error message if the longitude is out of bounds, otherwise an empty string.
 */
export const validateLongitude = (value: number): string => {
  if (value < -180 || value > 180) {
    return "Longitude must be between -180 and 180 degrees.";
  }
  return "";
};

/**
 * Validation function for phone extensions.
 *
 * @param {string} value - The phone extension to be validated.
 * @returns {string} - An error message if the phone extension is invalid, otherwise an empty string.
 */
export const validatePhoneExtension = (value: string): string => {
  if (value && !NumberRegx.test(value)) {
    return "Please enter a valid phone ext.";
  }
  return "";
};

/**
 * Validation function for contact person names.
 *
 * @param {string} value - The contact person name to be validated.
 * @returns {string} - An error message if the contact person name is invalid, otherwise an empty string.
 */
export const validateContactName = (value: string): string => {
  if (!value || !contactPersonRegex.test(value)) {
    return "Please enter a valid name.";
  }
  return "";
};

export const validateFirstReminderDays = (value: any, secondReminder: any, thirdReminder: any): string => {
  if ((parseInt(secondReminder) !== 0 && parseInt(value) >= parseInt(secondReminder)) || (parseInt(thirdReminder) !== 0 && parseInt(value) >= parseInt(thirdReminder))) {
    return "First reminder should be less than second and third reminder.";
  }
  return "";
};

export const validateSecondReminderDays = (value: any, firstReminder: any, thirdReminder: any): string => {
  if (parseInt(thirdReminder) !== 0 && parseInt(firstReminder) !== 0 && parseInt(value) < parseInt(firstReminder)) {
    return "Second reminder should be greater than first reminder.";
  }
  if(parseInt(thirdReminder) !== 0 && parseInt(value) > parseInt(thirdReminder)){
    return "Second reminder should be less than third reminder.";
  }
  return "";
};

export const validateThirdReminderDays = (value: any, firstReminder: any, secondReminder: any): string => {
  if(parseInt(firstReminder) === 0 && parseInt(secondReminder) === 0 && parseInt(value) === 0){
    return "";
  }
  if ((parseInt(value) < parseInt(firstReminder)) || (parseInt(value) < parseInt(secondReminder))) {
    return "Third reminder should be greater than first and second reminder.";
  }
  return "";
};
